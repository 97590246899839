.carousel {
    overflow: hidden;
    min-height: 550px;
    margin-top: 25px;
    position: relative;
}

.carousel-text {
   width: 55%;
   text-align: justify;
   font-size: calc(0.9vw + 2.5px);
}

.inner{
    display: flex;
    transition: transform 0.3s;
    width: 1000%; /* To change with the number of carousel items, 1 item = 100% */
    min-height: 550px;
    align-items: center;
    justify-content: center;
}

.carousel-item {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 200px;
    flex-direction: column;
    background-color: #fff;
    color: #252525;
    opacity: 1;
}

.indicators {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  
  .indicators > button {
    padding: 5px;
    margin: 5px;
    background-color: #252525;
    opacity: 0.6;
    cursor: pointer;
    border-radius: 25px;
    margin-bottom: 20px;
  }
  
  .indicators > button.active {
    opacity: 1;
    transform: scale(1.3);
  }

  .falafel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .left {
    position: absolute;
    left: 150px;
    z-index: 999;
    top: 50%;
    transform: translateY(-50%);
  }


  .right {
    position: absolute;
    z-index: 999;
    right: 150px;
    top: 50%;
    transform: translateY(-50%);
  }

  .left svg, .right svg {
    width: 40px;
  }

  .victim {
    margin-bottom: 25px;
  }

  
.carousel img {
  width: unset!important;
}